@import 'styles/colors.scss';

.Popup {
  &-Content {
    background: $white;
    border-radius: 1.6rem;
    left: 50%;
    padding: 1.6rem;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 96rem) {
      height: 100dvh;
      left: 0;
      top: 0;
      transform: none;
      width: 100%;
    }
  }

  &-Overlay {
    background: rgba(0, 0, 0, 0.4);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
}