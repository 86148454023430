@import 'styles/colors.scss';

.Header {
  background: $white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  left: 0;
  padding: 0.8rem 1.6rem;
  position: fixed;
  right: 0;
  top: 0;
}

.Logo {
  color: $text-color;
  font-family: alexa-std, sans-serif;
  font-size: 2.4rem;
  letter-spacing: 1px;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
}