@import 'styles/_colors.scss';

.Main {
  margin: 0 auto;
  max-width: 124rem;
  padding: 4.8rem 1.6rem;

  &-List {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    list-style: none;
    padding: 0;
  }
}