.Filters {
  &-Actions {
    display: flex;
    gap: 1.6rem;
    justify-content: center;
    margin-top: 1.6rem;
  }

  &-Depots {
    display: grid;
    gap: 0.4rem;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0.4rem;


    label {
      display: flex;
      gap: 0.4rem;
    }
  }

  &-Subtitle {
    font-size: 1.8rem;
    margin: 0;
    margin-top: 1.6rem;
  }

  &-Title {
    font-size: 2.4rem;
    margin: 0;
    text-align: center;
  }

  &-VehicleNumber {
    input {
      padding: 0.4rem 0.8rem;
      width: 100%;
    }
  }
}