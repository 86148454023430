@import 'styles/colors.scss';

.ActionButton {
  align-items: center;
  background: $primary-color;
  border: none;
  border-radius: 4.8rem;
  bottom: 1.6rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.5), 0 4px 6px -4px rgb(0 0 0 / 0.5);
  cursor: pointer;
  display: flex;
  height: 4.8rem;
  justify-content: center;
  user-select: none;
  position: fixed;
  right: 1.6rem;
  width: 4.8rem;

  &:hover {
    filter: brightness(1.05);
  }

  &:active {
    box-shadow: 0 7px 10px -3px rgb(0 0 0 / 0.5), 0 4px 6px -4px rgb(0 0 0 / 0.5);
    filter: brightness(0.95);
  }

  path {
    stroke: $white;
  }
}