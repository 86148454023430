@import 'styles/colors.scss';

.Button {
  border: none;
  border-radius: 0.8rem;
  color: $white;
  cursor: pointer;
  padding: 0.8rem 1.6rem;

  &:hover {
    filter: brightness(1.05);
  }

  &:active {
    filter: brightness(0.95);
  }

  &-primary {
    background: $primary-color;
  }

  &-secondary {
    background: $secondary-color;
  }

  &-tertiary {
    background: $tertiary-color;
  }
}