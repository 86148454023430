@import 'styles/_colors.scss';

* {
  box-sizing: border-box;
  user-select: none;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #fff9ed;
  color: $text-color;
  font-family: 'Nunito', sans-serif;
  font-size: 1.6rem;

  &:has(.Popup-Overlay) {
    overflow: hidden;
  }
}