@import 'styles/_colors.scss';

.MobileBar {
  background-color: $white;
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;
  bottom: 0;
  display: none;
  left: 0;
  padding: 1.6rem;
  position: fixed;
  right: 0;

  @media (max-width: 960px) {
    // display: flex;
    justify-content: space-around;
  }

  svg {
    filter: drop-shadow(0 1px 3px 0 rgb(0 0 0 / 0.1));
    height: 3.2rem;
    width: 3.2rem;

    path {
      stroke: $primary-color;
    }

    &:not(:first-of-type) {
      path {
        stroke: #d1d1d6;
      }
    }
  }
}