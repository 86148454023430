@import 'styles/colors.scss';

.VehicleCard {
  background: $white;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
  padding: 1.6rem;

  &-Direction,
  &-NextStop {
    align-items: center;
    display: flex;
    gap: 0.4rem;

    svg {
      width: 1.6rem;

      path {
        fill: $text-color;
      }
    }
  }

  &-Header {
    display: flex;
    justify-content: space-between;
  }

  &-LastSeen {
    align-items: center;
    display: flex;
    gap: 0.4rem;

    path:nth-of-type(1) {
      fill: $primary-color
    }
  }

  &-Number {
    margin: 0;
  }
}